import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import Layout from "../components/Layout";
import HTMLContent from "../components/HTMLContent";
import PageBanner from "../components/PageBanner";
import Icon from "../components/Icon";
import PreviewCompatibleImage from "../components/PreviewCompatibleImage";
import PhoneNumberLink from "../components/PhoneNumberLink";

export const AdmissionsPageTemplate = (props) => {
  const {
    description,
    banner,
    characteristics,
    applicationSteps: { step1, step2, step3, step4 },
    additionalInfo,
    openHouses,
    AdditionalInfoDescription,
    OpenHousesDescription,
  } = props;
  return (
    <>
      <PageBanner>
        <h1 className="page-banner-header">ADMISSIONS</h1>
        <p className="width-container">{description}</p>
      </PageBanner>
      <div className="layout-container">
        <div className="top-image-container">
          <div className="admissions-header">
            <span>JOIN OUR COMMUNITY</span>
          </div>
          <div className="grid-container position-relative">
            <div className="admissions-overlay" />
            <div className="grid-item-4">
              <PreviewCompatibleImage imageData={banner.image1} />
            </div>
            <div className="grid-item-4">
              <PreviewCompatibleImage imageData={banner.image2} />
            </div>
            <div className="grid-item-4">
              <PreviewCompatibleImage imageData={banner.image3} />
            </div>
            <div className="grid-item-4">
              <PreviewCompatibleImage imageData={banner.image4} />
            </div>
          </div>
        </div>
        <section className="body-padding text-center">
          <h5 className="width-container admissions-h5">
            Appropriate candidates for Philips Academy exhibit the following
            characteristics:
          </h5>
          <div className="char-circle-container grid-container">
            {characteristics.map(({ item }, index) => {
              return (
                <div key={`circle-${index}`} className="char-circle">
                  {item}
                </div>
              );
            })}
          </div>
        </section>
        <section className="body-id-section" id="how-to-apply">
          <div className="application-header text-center">
            <h3>Ready to Apply?</h3>
            <p>Just follow these steps!</p>
          </div>
          <div className="apply-grid">
            <div className="apply-item">
              <div className="number">1</div>
              <div className="content">
                <p>
                  Complete the online information request. To get started,
                  please{" "}
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={step1.linkURL}
                  >
                    <strong>click here</strong>
                  </a>
                  .
                </p>
              </div>
            </div>
            <div className="apply-icon">
              <Icon iconName="chevronRight" width={40} />
            </div>
            <div className="apply-item">
              <div className="number">2</div>
              <div className="content">
                <p>
                  Contact {step2.contactName}, at{" "}
                  <PhoneNumberLink phoneNumber={step2.contactPhone} /> or via{" "}
                  <a href={`mailto:${step2.contactEmail}`}>email</a> to set up a
                  parent visit.
                </p>
              </div>
            </div>
            <div className="apply-icon">
              <Icon iconName="chevronRight" width={40} />
            </div>
            <div className="apply-item">
              <div className="number">3</div>
              <div className="content">
                <p>{step3.text}</p>
              </div>
            </div>
            <div className="apply-icon">
              <Icon iconName="chevronRight" width={40} />
            </div>
            <div className="apply-item">
              <div className="number">4</div>
              <div className="content">
                <p>{step4.text}</p>
              </div>
            </div>
          </div>
        </section>
        <section className="body-padding">
          <div className="grid-container add-width">
            <div className="grid-item grid-item-2">
              <div className="body-img">
                <PreviewCompatibleImage
                  imageData={additionalInfo.image}
                  alt="Students studying"
                />
              </div>
            </div>
            <div className="grid-item grid-item-2">
              <h4 className="slightly-bigger">{additionalInfo.heading}</h4>
              {AdditionalInfoDescription}
              <div className="bottom-btn-container">
                <a
                  className="btn primary-btn full-width-sm"
                  target="_blank"
                  rel="noopener noreferrer"
                  href={step1.linkURL}
                >
                  VIEW ONLINE INQUIRY FORM
                </a>
              </div>
            </div>
          </div>
        </section>
        <section className="body-id-section" id="open-houses">
          <div className="landing-header-container text-center">
            <h3>{openHouses.heading}</h3>
          </div>
          <div className="grid-container add-width row-reverse-section">
            <div className="grid-item grid-item-2">
              <div className="body-img">
                <PreviewCompatibleImage
                  imageData={openHouses.image}
                  alt="Students studying"
                />
              </div>
            </div>
            <div className="grid-item grid-item-2">
              {OpenHousesDescription}
              <div className="bottom-btn-container">
                <a
                  href={openHouses.linkURL}
                  className="btn primary-btn full-width-sm"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  CONTACT US TODAY
                </a>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

AdmissionsPageTemplate.propTypes = {
  description: PropTypes.string,
  banner: PropTypes.object,
  characteristics: PropTypes.array,
  applicationSteps: PropTypes.object,
  additionalInfo: PropTypes.object,
  openHouses: PropTypes.object,
  AdditionalInfoDescription: PropTypes.node,
  OpenHousesDescription: PropTypes.node,
};

const AdmissionsPage = ({ data }) => {
  const {
    markdownRemark: {
      frontmatter: {
        description,
        banner,
        characteristics,
        applicationSteps,
        additionalInfo,
        openHouses,
      },
    },
  } = data;
  return (
    <Layout
      pageTitle={`Admissions`}
      ogUrl="/admissions/"
      pageDecription={
        "Philips Academy of North Carolina admits students of any race, color, national and ethnic origin to all the rights, privileges, programs, and activities generally accorded or made available to students at the school. Learn more about the admissions process at Philips Academy."
      }
    >
      <AdmissionsPageTemplate
        description={description}
        banner={banner}
        characteristics={characteristics}
        applicationSteps={applicationSteps}
        additionalInfo={additionalInfo}
        openHouses={openHouses}
        AdditionalInfoDescription={
          <HTMLContent
            content={
              data.markdownRemark.fields.frontmatterMarkdownHTML.additionalInfo
                .markdown
            }
          />
        }
        OpenHousesDescription={
          <HTMLContent
            content={
              data.markdownRemark.fields.frontmatterMarkdownHTML.openHouses
                .markdown
            }
          />
        }
      />
    </Layout>
  );
};

AdmissionsPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
};

export default AdmissionsPage;

export const admissionsPageQuery = graphql`
  query AdmissionsPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        banner {
          image1 {
            childImageSharp {
              fluid(maxWidth: 500, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          image2 {
            childImageSharp {
              fluid(maxWidth: 500, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          image3 {
            childImageSharp {
              fluid(maxWidth: 500, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          image4 {
            childImageSharp {
              fluid(maxWidth: 500, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        characteristics {
          item
        }
        applicationSteps {
          step1 {
            linkURL
          }
          step2 {
            contactName
            contactPhone
            contactEmail
          }
          step3 {
            text
          }
          step4 {
            text
          }
        }
        additionalInfo {
          heading
          image {
            childImageSharp {
              fluid(maxWidth: 850) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        openHouses {
          heading
          image {
            childImageSharp {
              fluid(maxWidth: 850) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          linkURL
        }
        description
      }
      fields {
        frontmatterMarkdownHTML {
          additionalInfo {
            markdown
          }
          openHouses {
            markdown
          }
        }
      }
    }
  }
`;
