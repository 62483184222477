import React from 'react'
import PropTypes from 'prop-types'

const HTMLContent = ({ content }) => (
  <div dangerouslySetInnerHTML={{ __html: content }} />
)

HTMLContent.propTypes = {
  content: PropTypes.node
}

export default HTMLContent
