import React from "react"
import bubbles from "../images/bubbles.svg"

const PageBanner = ({children}) => {
    return (
        <div className={`page-banner-container`}>
            <div style={{backgroundImage: `url(${bubbles})`}} className='page-banner'>
                {children}
            </div>
            <div className='svg-container'>
                <svg xmlns="http://www.w3.org/2000/svg" version="1.1" preserveAspectRatio="none" viewBox="0 0 100 20" width="100%" height="100%">
                    <path fill='currentColor' d="M0 0C0.33 0.13 0.53 0.21 0.62 0.24C32.55 12.93 68.13 12.84 100 0C100 0 100 0 100 0L100 20L0 20L0 0Z" />
                </svg>
            </div>
        </div>
    )
}

export default PageBanner